<template>
    <div class="container">
        <NavItem msg="History" link="history" icon="fa-solid fa-clock"/>
        <NavItem msg="Workout" link="workout" icon="fa-solid fa-plus"/>
        <NavItem msg="Profile" link="profile" icon="fa-solid fa-user"/>
    </div>
</template>
  
<script>
import NavItem from './NavItem.vue'

export default {
    name: 'Navbar',
    components: {NavItem},
    props: {
        msg: String
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .container {
        display: flex;
        background: gray;
        align-content: stretch;
        flex-grow: 1;
        justify-content: center;
        position:fixed;
        bottom:0;
        width: 100vw;
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        box-shadow: 2vh 2vh 2vh 2vh black;
    }

</style>
  