import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import { onAuthStateChanged, getAuth } from 'firebase/auth'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
/* import specific icons */
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { faClock } from '@fortawesome/free-solid-svg-icons'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import { faDumbbell } from '@fortawesome/free-solid-svg-icons'
import './registerServiceWorker'

/* add icons to the library */
library.add(faUser)
library.add(faClock)
library.add(faPlus)
library.add(faCog)
library.add(faDumbbell)

const getCurrentUser = () => {
    return new Promise((resolve, reject) => {
        const removeListener = onAuthStateChanged(
            getAuth(),
            (user) => {
                removeListener();
                resolve(user);
                console.log('user: ' + user)
            },
            reject
        );
    });
}
console.log(getCurrentUser())

createApp(App).use(router)
.component('font-awesome-icon', FontAwesomeIcon)
.mount('#app')