// import Vue from 'vue'
// import Router from 'vue-router'
import Dashboard from '../components/Dashboard.vue'
import History from '../components/History.vue'
import Profile from '../components/Profile.vue'
import Login from '../components/Login.vue'

// Vue.use(Router)

// export default new Router({
//     routes: [
      
//     ]
// })


//vue 3
//import * as Vue from 'vue';
import * as VueRouter from 'vue-router';

const routes = [
    { path: '/', component: Dashboard },
    { path: '/history', component: History },
    { path: '/workout', component: Dashboard },
    { path: '/stats', component: Dashboard },
    { path: '/profile', component: Profile },
    { path: '/login', component: Login },
    { path: '/settings', component: Profile },
];

const router = VueRouter.createRouter({
  history: VueRouter.createWebHistory(),
  routes,
});

export default router

//Vue.createApp(App).use(router).mount('#app');