


  <template>
    <div>
        <button v-if="!user" @click="logIn">Sign In with Google</button>
        <button v-if="user" @click="logOut">Sign Out</button>
        <!-- <span>{{ user }}</span> -->
        <span v-if="user">{{ welcomeMessage }}</span>
        <br/>
        <img v-if="user" :src="user.photoURL"/>
    </div>
</template>
  
<script>

import { getAuth, signInWithRedirect, getRedirectResult, signInWithCredential, signOut, GoogleAuthProvider } from "firebase/auth";
import { provider } from './../firebase'

export default {
name: 'Login',
props: {
    msg: String
},
data: function(){
    return {
        user: null
    }
},
computed: {
    welcomeMessage(){
        return `Welcome, ${this.user?.displayName}!`
    }
},
methods: {
    logIn: function(){
        const auth = getAuth();
        signInWithRedirect(auth, provider);
    },
    logOut: function(){
        const auth = getAuth();
        signOut(auth).then(() => {
        // Sign-out successful.
        }).catch((error) => {
        // An error happened.
        console.log(error);
        });
    },
    authorize: function(){
        const auth = getAuth();
        if (auth.currentUser){
            console.log('already logged in.')
            this.user = auth.currentUser;
            return;
        }
            
        getRedirectResult(auth)
        .then((result) => {
            // This gives you a Google Access Token. You can use it to access Google APIs.
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;

            // The signed-in user info.
            const user = result.user;
            // IdP data available using getAdditionalUserInfo(result)
            this.user = user;
            console.log(`User: ${user.email}; Token: ${token}`)

            console.log('Getting id from user...')
            var id_token = user.getAuthResponse().id_token;

            console.log(`Token: ${id_token}`)

            //const credential = GoogleAuthProvider.credential(id_token);


            console.log('signing into firebase with credentials...')
            signInWithCredential(auth, credential).catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(`${errorCode}: ${errorMessage}`)
            // ...
            });
        }).catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            console.log(errorCode);
            const errorMessage = error.message;
            console.log(errorMessage);
            // The AuthCredential type that was used.
            const credential = GoogleAuthProvider.credentialFromError(error);
            console.log(credential);
            return;
        });


    }
},
created() {
    console.log(provider);
},
mounted() {
    this.authorize();
}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
  