<template>
  <div>
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <Header/>
    <router-view />
    <Navbar/>
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Navbar from './components/Navbar.vue'

import { onMounted, ref } from 'vue'
import { getAuth, onAuthStateChanged } from 'firebase/auth'

const isLoggedIn = ref(false);

let auth;
onMounted(() =>{
  
});
export default {
  name: 'App',
  components: {
    Header,
    Navbar,
  },
  mounted(){
    auth = getAuth();
    onAuthStateChanged(auth, (user) => {
        isLoggedIn.value = user != null;
        console.log(`USER: ${user}`)
    })
    console.log('App Ready!')
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin-top: 8vh;
  
}

body {
  background-color: #2c3e50;
  color: whitesmoke;
  margin: 0px;
}

html {
    overflow: scroll;
    overflow-x: hidden;
}
::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}

Navbar {
 position: absolute;
 top:0px;
}
</style>
