<template>
    <div>
        <!-- <Workout :workout="workout"/> -->
        <button @click="add">New Workout</button>
        <div>
            <input @focus="$event.target.select()" placeholder="weight" type="number" v-model="weight" />
            <input @focus="$event.target.select()" placeholder="reps" type="number" v-model="reps"/>
        </div>
        <button @click="selectedExercise = ex" v-for="ex in exercises" :key="ex" :class="selectedExercise == ex ? 'highlight' : ''">{{ ex }}</button>
        <AddWorkout v-if="0 == 1" name="name"/>
    </div>
</template>
  
<script>
import History from './History.vue'
// import Workout from './Workout.vue'
import AddWorkout from './AddWorkout.vue'

//import { firebase } from 'firebase'
import { doc, addDoc, collection } from 'firebase/firestore';
import { db } from './../firebase'
import { getAuth } from 'firebase/auth'

import data from './../data/workouts.json'

export default {
    name: 'Dashboard',
    components: {AddWorkout},
    props: {
        msg: String
    },
    data: function() {
        return {
            currentComponent: History,
            workouts: data,
            weight: 0,
            reps: 0,
            user: null,
            selectedExercise: '',
            exercises: ['bench', 'squat', 'deadlift'],
            workout: {name: 'new',
                weight: 135, reps: 10}
        }
    },
    methods: {
        add: async function(){
            if (!this.user) {
                console.log("Not logged in.")
                return
            }
            try{
                let w = {
                    name: this.selectedExercise,
                    date: new Date().getTime(),
                    //date: Firestore.FieldValue.serverTimestamp(),
                    weight: this.weight,
                    reps: this.reps
                }
                const docRef = doc(db, "users", this.user.uid);
                const colRef = collection(docRef, "workouts")
                addDoc(colRef, w);
                console.log('Success')
                //await setDoc(doc(db, 'users/' + this.user.uid + '/workouts'), w);
            }
            catch(e){
                console.log(e)
            }
        },
        save: function(){
            console.log(this.weight);
            console.log(this.reps);
        }
    },
    created(){
        console.log(this.workouts);
        const auth = getAuth();
        if (auth.currentUser)
            this.user = auth.currentUser;

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    button {
        background: blue;
        color: whitesmoke;
        font-size: 2rem;
        padding: 2vw;
        margin: 2vw;
        border-radius: 4px;
    }

    input {
        background: gray;
        color: white;
        text-align: center;
        border-radius: 5px;
        border-color: transparent;
        font-size: 5vw;
        margin: 2vw;
        padding: 1vw;
        width: 40vw;
    }

    ::placeholder{
        color: white;
    }

    .highlight {
        background: black;
    }

    .footer{
        position: fixed;
        bottom: 0px;
        width: 100%;
        padding: 0px;
        margin: 0px;
    }

</style>
  