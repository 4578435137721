<template>
    <div class="container">
        <NavItem msg="" link="/" icon="fa-solid fa-dumbbell"/>
        <NavItem msg="" link="settings" icon="fa-solid fa-cog"/>
    </div>
</template>
  
<script>
import NavItem from './NavItem.vue'

export default {
    name: 'Header',
    components: {NavItem},
    props: {
        msg: String
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .container {
        display: flex;
        background: gray;
        align-content: stretch;
        flex-grow: 1;
        justify-content: center;
        position:fixed;
        top:0;
        width: 100vw;
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        box-shadow: 1px 1px 1px 1px black;
    }

</style>
  