<template>
    <div>
        <span>{{ username }}</span>
    </div>
</template>
  
<script>
export default {
name: 'Profile',
props: {
    user: Object
},
data: function(){
    return {
        firstName: String,
        lastName: String,
    }
},
computed: {
    username(){
        return `${this.firstName[0]}. ${this.lastName}` 
    }
},
methods: {
    loadUser: function(){
        this.firstName = "Blake";
        this.lastName = "Lombard";
    }
},
created() {

},
mounted() {
    this.loadUser();
    this.$router.push('/login')
}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
  