<template>
    <router-link :to="'/' + link" tag="button">
        <div class="card">
        <font-awesome-icon :icon="icon" />
        {{ msg }}
        </div>
    </router-link>
</template>
  
<script>

export default {
    name: 'NavItem',
    props: {
        msg: String,
        link: String,
        icon: String
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .container {
        display: flex;
    }

    .card {
        /* background-color: rgba(0, 0, 0, 0.2);
        border-radius: 5px; */
        background-color: transparent;
        margin: 10px;
        padding: 5px;
        align-items: center;
        font-size: 6vw;
        color: whitesmoke;
    }

    /* Router link creates a tag */
    a { text-decoration: none; }

</style>
  