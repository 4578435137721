//var firebase = require('firebase');
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore'
import { GoogleAuthProvider, getAuth, browserLocalPersistence } from "firebase/auth";
// import { firebaseui } from 'firebaseui'


const config = {
  apiKey: "AIzaSyD-UVUAiVmDoOWtHOTd1mcxr1ZyK8uk9hE",
  authDomain: "workout-78090.firebaseapp.com",
  projectId: "workout-78090",
  storageBucket: "workout-78090.appspot.com",
  messagingSenderId: "763269925238",
  appId: "1:763269925238:web:b5487af1e1e914af269257"
};

//const firebaseApp = firebase.initializeApp(config)

const app = initializeApp(config);

const db = getFirestore(app)

const provider = new GoogleAuthProvider();
provider.setCustomParameters({
  prompt: "select_account"
});

// var ui = new firebaseui.auth.AuthUI(provider);
// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);
auth.setPersistence(browserLocalPersistence);

export {
  db,
  provider,
  auth
}