<template>
    <div>
        <h1>History</h1>
        <Workout v-for="w in workouts" v-bind:workout="w" :key="w"/>
    </div>
</template>
  
<script>

import { db } from '../firebase.js'
import { doc, collection, getDocs } from 'firebase/firestore';
import { getAuth} from "firebase/auth";

import moment from 'moment'

import Workout from './Workout.vue';

export default {
name: 'History',
components: {Workout},
props: {
    msg: String
},
data (){
    return {
        lifts: ['bench', 'squat', 'deadlift'],
        workouts: [],
        user: null
    }
},
methods: {
    load: async function(){
        const auth = getAuth();
        if (!auth.currentUser) return;
        console.log('already logged in.')
        this.user = auth.currentUser;
        console.log(this.user.uid);
        //const querySnapshot = await getDocs(collection(db, 'users').document(this.user.uid).collection('workouts'));
        // Main Collection Users, get document by id
        const docRef = doc(db, "users", this.user.uid);
        // Users/Id documents holds collections named workouts
        const colRef = collection(docRef, "workouts")
        // The workouts collection holds every workout doc
        const querySnapshot = await getDocs(colRef)
        let previous = moment();
        querySnapshot.forEach(async (doc) => {
            //console.log(`${doc.id} => ${doc.data()}`)
            let exercise = doc.data();
            let workout = {
                date: exercise.date,
                exercises: []
            };

            if (!moment(exercise.date).isSame(previous, 'day') || this.workouts.length == 0){
                this.workouts.push(workout);
                console.log('same');
            }
            //console.log(this.workouts);

            this.workouts[this.workouts.length-1].exercises.push(exercise);

            previous = moment(workout.date);
        })
        //this.workouts = this.workouts.reverse();
    }
},
created() {
    this.load();
}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .row {
        position: relative;
        margin-top: 5px;
        margin-right: 10px;
    }
    div {
        position: relative;
        display: inline;
    }
    /* button {
        width: 100%;
        font-size: 3rem;
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        margin-right: 10px;
        margin-bottom: 10px;
    } */
    span{
        font-size: 1rem;
        display: block;
        font-style: bold;
    }
    .stack {
        font-size: 8px;
        float: left;
    }
    .day {
        font-size: 12px;
    }
</style>
  