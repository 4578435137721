<template>
    <div class="container">
        <h4 class="center">{{ displayDate }}</h4>
        <button v-if="!expanded" @click="toggle">
            <span v-for="(value, name) in exerciseSums" :key="value">x{{value}} {{name}}</span>
        </button>
        <button v-if="expanded" @click="toggle">
            <span v-for="e in exercises" :key="e">
                {{ e.weight }} x {{ e.reps }} {{ e.name }}
            </span>
        </button>
    </div>
</template>
  
<script>

import moment from 'moment'

export default {
name: 'Workout',
    props: {
        msg: String,
        workout: Object,
        // lifts: ['bench', 'squat']
    },
    data(){
        return {
            lifts: ['bench', 'squat'],
            expanded: false,
        }
    },
    methods: {
        toggle(){
            this.expanded = !this.expanded;
            //console.log(typeof(this.exerciseSums))
        },
        print(){
            console.log(this.workout.reps);
        }
    },
    computed: {
        exercises(){
            return this.workout?.exercises;
        },
        exerciseSums(){
            return this.workout?.exercises.reduce(function (acc, curr) {
                return acc[curr.name] ? ++acc[curr.name] : acc[curr.name] = 1, acc
            }, {});
        },
        displayDate(){
            return moment(this.workout.date).format('ddd, MMM DD');
        }
    },
    mounted(){
        //console.log(this.workout);
    },
    created(){
        //console.log(this.workout)
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container{
    display:flex;
    align-content: stretch;
    flex-grow: 1;
    column-fill: 1fr 1fr 1fr;
}

.center{
    display: inline-flex;
    align-items: center;
}

div {
    position: relative;
    display: inline;
}
button {
    width: 100%;
    font-size: 3rem;
    color: whitesmoke;
    background-color: rgba(0, 0, 0, 0.2);
    padding: 1vw;
    margin: 2vw;
    border-radius: 8px;
    /* margin-right: 10px; */
}
span{
    color: whitesmoke;
    font-size: 1rem;
    display: block;
    font-style: bold;
    text-align: center;
}

h4 {
    margin: 2vw;
}

</style>
  