<template>
    <div>
        <input placeholder="name" :value="name"/>
    </div>
</template>
  
<script>
export default {
name: 'AddWorkout',
props: {
    name: String,
},
data: function(){
    return {

    }
},
computed: {
    
},
methods: {

},
created() {

},
mounted() {

}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
  